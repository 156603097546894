import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useFetcher } from "@remix-run/react";
import { useMediaQuery } from "react-responsive";
import useDetectStandalone from "../utils/useDetectStandalone";
export const AppContext = createContext({});

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [topPanelHeight, setTopPanelHeight] = useState(0);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false);
  const [navbarVisibilityHidden, setNavbarvisibilityHidden] = useState(false);
  const [isMobileInfinitePrompt, setIsMobileInfinitePrompt] = useState(false);
  const [infinitePromptActive, setInfinitePromptActive] = useState(false);
  const [infinitePromptContext, setInfinitePromptContext] = useState(null);
  const [infinitePromptAnswerReceived, setInfinitePromptAnswerReceived] = useState(false);
  const [voiceJournalingActive, setVoiceJournalingActive] = useState(false);
  const [onNotificationModalClose, setOnNotificationModalClose] = useState(0);
  const [tab, setTab] = useState("");
  const [prevTab, setPrevTab] = useState("");

  const [previousFramework, setPreviousFramework] = useState({ id: "" });
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [isFrameworkCoachMode, setFrameworkCoachMode] = useState(false);
  const [hideUI, setHideUI] = useState(false);

  const [entryId, setEntryId] = useState(null);

  const [isInTextAnalysis, setIsInTextAnalysis] = useState(false);
  const [inTextAnalysisTasks, setInTextAnalysisTasks] = useState([]);
  const [inTextAnalysisPrompt, setInTextAnalysisPrompt] = useState({
    active: false,
    text: "",
    prompt: "",
  });
  const [lastSelectedText, setLastSelectedText] = useState(null);

  const [mentors, setMentors] = useState([]);
  const [frameworks, setFrameworks] = useState([]);

  const [isTopBarHidden, setIsTopBarHidden] = useState(false);
  const [removeTopPadding, setRemoveTopPadding] = useState(false); // Used for Editor.

  const [onboardingProgress, setOnboardingProgress] = useState(null);

  const smallScreenQuery = useMediaQuery({ query: "(max-width: 767px)" });

  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    setSmallScreen(smallScreenQuery);
  }, [smallScreenQuery]);

  const isStandaloneQuery = useDetectStandalone(); // detect if a user has added a website to the home screen on iOS using the "Save to Home Screen" feature
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    setIsStandalone(isStandaloneQuery);
  }, [isStandaloneQuery]);

  useEffect(() => {
    if (isNavbarHidden) {
      document.body.classList.add("navbar-hidden");
    } else {
      document.body.classList.remove("navbar-hidden");
    }
  }, [isNavbarHidden]);

  useEffect(() => {
    if (navbarVisibilityHidden) {
      document.body.classList.add("navbar-visibility-hidden");
    } else {
      document.body.classList.remove("navbar-visibility-hidden");
    }
  }, [navbarVisibilityHidden]);

  useEffect(() => {
    if (selectedFramework) {
      if (selectedFramework.id !== previousFramework.id) {
        // Add tag to entry
        setPreviousFramework(selectedFramework);
      }
    }
  }, [selectedFramework]);

  return (
    <AppContext.Provider
      value={{
        topPanelHeight,
        setTopPanelHeight,
        isRegistered,
        setIsRegistered,
        isNavbarHidden,
        setIsNavbarHidden,
        onNotificationModalClose,
        setOnNotificationModalClose,
        setIsMobileInfinitePrompt,
        isMobileInfinitePrompt,
        infinitePromptActive,
        setInfinitePromptActive,
        infinitePromptContext,
        setInfinitePromptContext,
        infinitePromptAnswerReceived,
        setInfinitePromptAnswerReceived,
        tab,
        setTab,
        prevTab,
        setPrevTab,
        selectedFramework,
        setSelectedFramework,
        voiceJournalingActive,
        setVoiceJournalingActive,
        isFrameworkCoachMode,
        setFrameworkCoachMode,
        isInTextAnalysis,
        setIsInTextAnalysis,
        inTextAnalysisTasks,
        setInTextAnalysisTasks,
        inTextAnalysisPrompt,
        setInTextAnalysisPrompt,
        isTopBarHidden,
        setIsTopBarHidden,
        removeTopPadding,
        setRemoveTopPadding,
        mentors,
        setMentors,
        frameworks,
        setFrameworks,
        navbarVisibilityHidden,
        setNavbarvisibilityHidden,
        entryId,
        setEntryId,
        setLastSelectedText,
        lastSelectedText,
        hideUI,
        setHideUI,
        onboardingProgress,
        setOnboardingProgress,
        smallScreen,
        isStandalone,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// const useMobile = () => {
//   const [mobile, setMobile] = useState(
//     typeof window !== "undefined" ? window.innerWidth <= 768 : false
//   );

//   const handleWindowResize = useCallback(
//     debounce(() => {
//       if (typeof window !== "undefined") {
//         setMobile(window.innerWidth <= 768);
//       }
//     }, 1000),
//     []
//   );

//   useEffect(() => {
//     if (typeof window === "undefined") return;

//     window.addEventListener("resize", handleWindowResize);
//     return () => window.removeEventListener("resize", handleWindowResize);
//   }, [handleWindowResize]);

//   return mobile;
// };
